import React, { createElement, useEffect } from "react";
import { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ErrorPage } from "./Components";
import { GlobalState } from "./Data/Context";
import { Loader } from "./Utils";

const GeneratePage = (pageName, folder) => {
	const component = () => require(`./${folder}/${pageName}`).default;
	try {
		return createElement(component());
	} catch (error) {
		return <ErrorPage />;
	}
};

const PageRender = () => {
	const { auth, errors, clearErrors } = useContext(GlobalState);
	const { page, id, step } = useParams(),
		navigate = useNavigate(),
		escapeUsers = ["reseller", "users", "topuser", "upgrade", "standalone"],
		escape = ["pending", "app"],
		escapeProducts = [
			"airtime",
			"data",
			"tv-subscriptions",
			"airtime_pin",
			"bulk_sms",
			"education",
			"auto-buy",
			"electricity-bills",
			"cgwallet",
		],
		escapeAdmin = [
			"manager",
			"marketer",
			"billers",
			"superadmin",
			"developer",
			"accountant",
			"customer-care",
		],
		escapeWallet = [
			"bonus",
			"give-bonus",
			"bonus-data",
			"commissions",
			"referral",
			"my-wallet",
			"provider",
			"manage-transactions",
			"find-transactions",
			"manage-wallet",
			"wallet-funding",
			"manual",
		],
		escapeControl = [
			"electricity",
			"education",
			"broadcasts",
			"cables",
			"bonus",
			"data",
			"category",
			"biller",
			"network",
			"manage-products",
			"manage-cgwallet",
			"manage-cgwallet-history",
			"blacklist",
		];

	useEffect(() => {
		if (!auth?.isAuth) {
			if (errors?.errorText) {
				if (page !== "login" && page !== "register") {
					navigate("/");
				}
				clearErrors();
			}
		}
		if (auth?.isAuth) {
			if (page === "login" || page === "register") {
				navigate("/");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, auth?.isAuth, navigate, errors?.errorText]);

	if (auth.token && auth.loading) return <Loader />;

	let pageName = "";
	if (step) {
		if (
			(id === "biz" && ["verify"].includes(step)) ||
			(["purchase", "wallet"]?.includes(id) && ["admin"].includes(step))
		)
			pageName = `${page}/${id}/${step}`;
		else if (id === "commissions" && ["stat"].includes(step))
			pageName = `${page}/${id}/${step}`;
		else if (id === "app" && ["privacy", "terms"].includes(step))
			pageName = `${page}/${id}/${step}`;
		else pageName = `${page}/${id}/${"[id]"}`;
	} else if (id) {
		if (page === "users" && escapeUsers.includes(id))
			pageName = `${page}/${id}`;
		else if (page === "products" && escapeProducts.includes(id))
			pageName = `${page}/${id}`;
		else if (page === "controls" && escapeControl.includes(id))
			pageName = `${page}/${id}`;
		else if (page === "administration" && escapeAdmin.includes(id))
			pageName = `${page}/${id}`;
		else if (page === "transactions" && escape.includes(id))
			pageName = `${page}/${id}`;
		else if (page === "settings" && escape.includes(id))
			pageName = `${page}/${id}`;
		else if (page === "wallets" && escapeWallet.includes(id))
			pageName = `${page}/${id}`;
		else pageName = `${page}/[id]`;
	} else {
		pageName = `${page}`;
	}

	return GeneratePage(pageName, auth?.user ? "Pages" : "Screens");
};

export default PageRender;
