export const TOKEN = "HONOURWORLD_DASHBOARD";

export const LOGOUT = "LOGOUT";
export const LOGOUT_LOADING = "LOGOUT_LOADING";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const GET_USER = "GET_USER";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const GET_USER_LOADING = "GET_USER_LOADING";
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";

// ERRORS
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_ERRORS_TEXT = "GET_ERRORS_TEXT";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";
export const SEARCH_ALL_USERS = "SEARCH_ALL_USERS";
export const SEARCH_ALL_USERS_LOADING = "SEARCH_ALL_USERS_LOADING";
export const SEARCH_ALL_USERS_FAIL = "SEARCH_ALL_USERS_FAIL";
export const SEARCH_ALL_USERS_RELOAD = "SEARCH_ALL_USERS_RELOAD";

export const GET_SETTINGS = "GET_SETTINGS";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const UPDATE_SETTINGS_FAIL = "UPDATE_SETTINGS_FAIL";

export const PENDING_TRANSACTION = "PENDING_TRANSACTION";
export const PENDING_TRANSACTION_FAIL = "PENDING_TRANSACTION_FAIL";
export const SEARCH_TRANSACTION_PENDING = "SEARCH_TRANSACTION_PENDING";
export const SEARCH_TRANSACTION_PENDING_LOADING =
	"SEARCH_TRANSACTION_PENDING_LOADING";
export const SEARCH_TRANSACTION_PENDING_FAIL =
	"SEARCH_TRANSACTION_PENDING_FAIL";
export const SEARCH_TRANSACTION_PENDING_RELOAD =
	"SEARCH_TRANSACTION_PENDING_RELOAD";

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const GET_NOTIFICATION_LOADING = "GET_NOTIFICATION_LOADING";
export const GET_NOTIFICATION_FROM = "GET_NOTIFICATION_FROM";
export const GET_NOTIFICATION_FROM_FAIL = "GET_NOTIFICATION_FROM_FAIL";
export const GET_NOTIFICATION_TO = "GET_NOTIFICATION_TO";
export const GET_NOTIFICATION_TO_FAIL = "GET_NOTIFICATION_TO_FAIL";
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const SEND_NOTIFICATION_FAIL = "SEND_NOTIFICATION_FAIL";
export const GET_USER_NOTIFICATION = "GET_USER_NOTIFICATION";
export const GET_USER_NOTIFICATION_FAIL = "GET_USER_NOTIFICATION_FAIL";
export const SEARCH_NOTIFICATION = "SEARCH_NOTIFICATION";
export const SEARCH_NOTIFICATION_LOADING = "SEARCH_NOTIFICATION_LOADING";
export const SEARCH_NOTIFICATION_FAIL = "SEARCH_NOTIFICATION_FAIL";
export const SEARCH_NOTIFICATION_RELOAD = "SEARCH_NOTIFICATION_RELOAD";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";

export const SET_SUCCESS = "SET_SUCCESS";
export const SET_SUCCESS_FAIL = "SET_SUCCESS_FAIL";

export const ACTIVATE_USER = "ACTIVATE_USER";
export const DELETE_USER = "DELETE_USER";
export const ACTIVATE_USER_FAIL = "ACTIVATE_USER_FAIL";

// Slider
export const GET_SLIDER = "GET_SLIDER";
export const GET_SLIDER_FAIL = "GET_SLIDER_FAIL";
export const UPDATE_SLIDER = "UPDATE_SLIDER";
export const ADD_SLIDER = "ADD_DATA";
export const ADD_SLIDER_FAIL = "ADD_SLIDER_FAIL";
export const DELETE_SLIDER = "DELETE_SLIDER";

export const ADD_EMPLOYEE_FAIL = "ADD_EMPLOYEE_FAIL";

// ADMINS
export const ADD_ADMIN = "ADD_ADMIN";
export const ADD_ADMIN_FAIL = "ADD_ADMIN_FAIL";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";

export const GET_RESELLERS = "GET_RESELLERS";
export const GET_ALL_RESELLERS = "GET_ALL_RESELLERS";
export const GET_RESELLERS_FAIL = "GET_RESELLERS_FAIL";
export const SEARCH_RESELLERS = "SEARCH_RESELLERS";
export const SEARCH_RESELLERS_LOADING = "SEARCH_RESELLERS_LOADING";
export const SEARCH_RESELLERS_FAIL = "SEARCH_RESELLERS_FAIL";
export const SEARCH_RESELLERS_RELOAD = "SEARCH_RESELLERS_RELOAD";

export const GET_STANDALONE = "GET_STANDALONE";
export const GET_ALL_STANDALONE = "GET_ALL_STANDALONE";
export const GET_STANDALONE_FAIL = "GET_STANDALONE_FAIL";
export const SEARCH_STANDALONE = "SEARCH_STANDALONE";
export const SEARCH_STANDALONE_LOADING = "SEARCH_STANDALONE_LOADING";
export const SEARCH_STANDALONE_FAIL = "SEARCH_STANDALONE_FAIL";
export const SEARCH_STANDALONE_RELOAD = "SEARCH_STANDALONE_RELOAD";

export const GET_TOPUSERS = "GET_TOPUSERS";
export const GET_ALL_TOPUSERS = "GET_ALL_TOPUSERS";
export const GET_TOPUSERS_FAIL = "GET_TOPUSERS_FAIL";
export const SEARCH_TOPUSERS = "SEARCH_TOPUSERS";
export const SEARCH_TOPUSERS_LOADING = "SEARCH_TOPUSERS_LOADING";
export const SEARCH_TOPUSERS_FAIL = "SEARCH_TOPUSERS_FAIL";
export const SEARCH_TOPUSERS_RELOAD = "SEARCH_TOPUSERS_RELOAD";

export const GET_USERS = "GET_USERS";
export const GET_ALL_USER = "GET_ALL_USER";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const SEARCH_USERS = "SEARCH_USERS";
export const SEARCH_USERS_LOADING = "SEARCH_USERS_LOADING";
export const SEARCH_USERS_FAIL = "SEARCH_USERS_FAIL";
export const SEARCH_USERS_RELOAD = "SEARCH_USERS_RELOAD";

// ADMINS
export const ADD_MANAGERS = "ADD_MANAGERS";
export const ADD_MANAGERS_FAIL = "ADD_MANAGERS_FAIL";
export const GET_MANAGERS = "GET_MANAGERS";
export const GET_ALL_MANAGERS = "GET_ALL_MANAGERS";
export const GET_MANAGERS_FAIL = "GET_MANAGERS_FAIL";
export const SEARCH_MANAGERS = "SEARCH_MANAGERS";
export const SEARCH_MANAGERS_LOADING = "SEARCH_MANAGERS_LOADING";
export const SEARCH_MANAGERS_FAIL = "SEARCH_MANAGERS_FAIL";
export const SEARCH_MANAGERS_RELOAD = "SEARCH_MANAGERS_RELOAD";

// ADMINS
export const ADD_ACCOUNTANT = "ADD_ACCOUNTANT";
export const ADD_ACCOUNTANT_FAIL = "ADD_ACCOUNTANT_FAIL";
export const GET_ACCOUNTANT = "GET_ACCOUNTANT";
export const GET_ALL_ACCOUNTANT = "GET_ALL_ACCOUNTANT";
export const GET_ACCOUNTANT_FAIL = "GET_ACCOUNTANT_FAIL";
export const SEARCH_ACCOUNTANT = "SEARCH_ACCOUNTANT";
export const SEARCH_ACCOUNTANT_LOADING = "SEARCH_ACCOUNTANT_LOADING";
export const SEARCH_ACCOUNTANT_FAIL = "SEARCH_ACCOUNTANT_FAIL";
export const SEARCH_ACCOUNTANT_RELOAD = "SEARCH_ACCOUNTANT_RELOAD";

// ADMINS
export const ADD_DEVELOPERS = "ADD_DEVELOPERS";
export const ADD_DEVELOPERS_FAIL = "ADD_DEVELOPERS_FAIL";
export const GET_DEVELOPERS = "GET_DEVELOPERS";
export const GET_ALL_DEVELOPERS = "GET_ALL_DEVELOPERS";
export const GET_DEVELOPERS_FAIL = "GET_DEVELOPERS_FAIL";
export const SEARCH_DEVELOPERS = "SEARCH_DEVELOPERS";
export const SEARCH_DEVELOPERS_LOADING = "SEARCH_DEVELOPERS_LOADING";
export const SEARCH_DEVELOPERS_FAIL = "SEARCH_DEVELOPERS_FAIL";
export const SEARCH_DEVELOPERS_RELOAD = "SEARCH_DEVELOPERS_RELOAD";

// ADMINS
export const ADD_MARKETERS = "ADD_MARKETERS";
export const ADD_MARKETERS_FAIL = "ADD_MARKETERS_FAIL";
export const GET_MARKETERS = "GET_MARKETERS";
export const GET_ALL_MARKETERS = "GET_ALL_MARKETERS";
export const GET_MARKETERS_FAIL = "GET_MARKETERS_FAIL";
export const SEARCH_MARKETERS = "SEARCH_MARKETERS";
export const SEARCH_MARKETERS_LOADING = "SEARCH_MARKETERS_LOADING";
export const SEARCH_MARKETERS_FAIL = "SEARCH_MARKETERS_FAIL";
export const SEARCH_MARKETERS_RELOAD = "SEARCH_MARKETERS_RELOAD";

// ADMINS
export const ADD_CUSTOMERCARE = "ADD_CUSTOMERCARE";
export const ADD_CUSTOMERCARE_FAIL = "ADD_CUSTOMERCARE_FAIL";
export const GET_CUSTOMERCARE = "GET_CUSTOMERCARE";
export const GET_ALL_CUSTOMERCARE = "GET_ALL_CUSTOMERCARE";
export const GET_CUSTOMERCARE_FAIL = "GET_CUSTOMERCARE_FAIL";
export const SEARCH_CUSTOMERCARE = "SEARCH_CUSTOMERCARE";
export const SEARCH_CUSTOMERCARE_LOADING = "SEARCH_CUSTOMERCARE_LOADING";
export const SEARCH_CUSTOMERCARE_FAIL = "SEARCH_CUSTOMERCARE_FAIL";
export const SEARCH_CUSTOMERCARE_RELOAD = "SEARCH_CUSTOMERCARE_RELOAD";

// ADMINS
export const ADD_SUPERADMIN = "ADD_SUPERADMIN";
export const ADD_SUPERADMIN_FAIL = "ADD_SUPERADMIN_FAIL";
export const GET_SUPERADMIN = "GET_SUPERADMIN";
export const GET_ALL_SUPERADMIN = "GET_ALL_SUPERADMIN";
export const GET_SUPERADMIN_FAIL = "GET_SUPERADMIN_FAIL";
export const SEARCH_SUPERADMIN = "SEARCH_SUPERADMIN";
export const SEARCH_SUPERADMIN_LOADING = "SEARCH_SUPERADMIN_LOADING";
export const SEARCH_SUPERADMIN_FAIL = "SEARCH_SUPERADMIN_FAIL";
export const SEARCH_SUPERADMIN_RELOAD = "SEARCH_SUPERADMIN_RELOAD";

export const GET_CHOSEN_SERVICES = "GET_CHOSEN_SERVICES";
export const GET_CHOSEN_SERVICES_FAIL = "GET_CHOSEN_SERVICES_FAIL";

export const GET_TERMII = "GET_TERMII";
export const GET_CONNECTSMS = "GET_CONNECTSMS";
export const GET_CLUBKONNECT = "GET_CLUBKONNECT";
export const GET_HUSMO = "GET_HUSMO";
export const GET_HUSMO_FAIL = "GET_HUSMO_FAIL";
export const GET_DATASTATION = "GET_DATASTATION";
export const GET_KVDATA = "GET_KVDATA";
export const GET_ROSSYTECHS = "GET_ROSSYTECHS";
export const GET_MASKAWASUB = "GET_MASKAWASUB";
export const GET_VICKYBEST = "GET_VICKYBEST";
export const GET_DATAMESOLUTION = "GET_DATAMESOLUTION";
export const GET_DATASTATION_FAIL = "GET_DATASTATION_FAIL";
export const GET_KVDATA_FAIL = "GET_KVDATA_FAIL";
export const GET_TEETOP = "GET_TEETOP";
export const GET_KEMTECH = "GET_KEMTECH";
export const GET_MOAVOXPAY = "GET_MOAVOXPAY";
export const GET_TEETOP_FAIL = "GET_TEETOP_FAIL";

export const GET_VTPASS = "GET_VTPASS";
export const GET_VTPASS_FAIL = "GET_VTPASS_FAIL";

export const GET_RINGO_DATA = "GET_RINGO_DATA";
export const GET_RINGO_DATA_FAIL = "GET_RINGO_DATA_FAIL";

export const GET_OGDAMS = "GET_OGDAMS";
export const GET_OGDAMS_FAIL = "GET_OGDAMS_FAIL";

export const GET_RPI_DATA = "GET_RPI_DATA";
export const GET_RPI_DATA_FAIL = "GET_RPI_DATA_FAIL";

export const GET_MCD = "GET_MCD";
export const GET_MCD_FAIL = "GET_MCD_FAIL";

export const GET_UZOBEST = "GET_UZOBEST";
export const GET_UZOBEST_FAIL = "GET_UZOBEST_FAIL";

export const GET_RPI_DATA_PLAN = "GET_RPI_DATA_PLAN";
export const GET_RPI_DATA_PLAN_FAIL = "GET_RPI_DATA_PLAN_FAIL";

export const GET_HONOURWORLD_DATA = "GET_HONOURWORLD_DATA";
export const GET_HONOURWORLD_DATA_FAIL = "GET_HONOURWORLD_DATA_FAIL";

// Data Bundle
export const GET_DATA_BUNDLE = "GET_DATA_BUNDLE";
export const GET_DATA_BUNDLE_FAIL = "GET_DATA_BUNDLE_FAIL";
export const UPDATE_DATA_BUNDLE = "UPDATE_DATA_BUNDLE";
export const ADD_DATA_BUNDLE = "ADD_DATA_BUNDLE";
export const ADD_DATA_BUNDLE_FAIL = "ADD_DATA_BUNDLE_FAIL";
export const DELETE_DATA_BUNDLE = "DELETE_DATA_BUNDLE";
export const SEARCH_DATA_BUNDLE = "SEARCH_DATA_BUNDLE";
export const SEARCH_DATA_BUNDLE_LOADING = "SEARCH_DATA_BUNDLE_LOADING";
export const SEARCH_DATA_BUNDLE_FAIL = "SEARCH_DATA_BUNDLE_FAIL";
export const SEARCH_DATA_BUNDLE_RELOAD = "SEARCH_DATA_BUNDLE_RELOAD";

// Billers
export const GET_BILLER = "GET_BILLER";
export const GET_BILLER_FAIL = "GET_BILLER_FAIL";
export const UPDATE_BILLER = "UPDATE_BILLER";
export const ADD_BILLER = "ADD_BILLER";
export const ADD_BILLER_FAIL = "ADD_BILLER_FAIL";
export const DELETE_BILLER = "DELETE_BILLER";
export const SEARCH_BILLER = "SEARCH_BILLER";
export const SEARCH_BILLER_LOADING = "SEARCH_BILLER_LOADING";
export const SEARCH_BILLER_FAIL = "SEARCH_BILLER_FAIL";
export const SEARCH_BILLER_RELOAD = "SEARCH_BILLER_RELOAD";

// Products
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const ADD_PRODUCTS_FAIL = "ADD_PRODUCTS_FAIL";
export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const SEARCH_PRODUCTS_LOADING = "SEARCH_PRODUCTS_LOADING";
export const SEARCH_PRODUCTS_FAIL = "SEARCH_PRODUCTS_FAIL";
export const SEARCH_PRODUCTS_RELOAD = "SEARCH_PRODUCTS_RELOAD";

// Category
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const SEARCH_CATEGORY = "SEARCH_CATEGORY";
export const SEARCH_CATEGORY_LOADING = "SEARCH_CATEGORY_LOADING";
export const SEARCH_CATEGORY_FAIL = "SEARCH_CATEGORY_FAIL";
export const SEARCH_CATEGORY_RELOAD = "SEARCH_CATEGORY_RELOAD";

// Billers'
export const GET_BILLER_CHOOSEN = "GET_BILLER_CHOOSEN";
export const GET_BILLER_CHOOSEN_FAIL = "GET_BILLER_CHOOSEN_FAIL";
export const GET_BILLER_RINGO = "GET_BILLER_RINGO";
export const GET_BILLER_RINGO_FAIL = "GET_BILLER_RINGO_FAIL";
export const GET_BILLER_HUSMO = "GET_BILLER_HUSMO";
export const GET_BILLER_CLUBKONNECT = "GET_BILLER_CLUBKONNECT";
export const GET_BILLER_HUSMO_FAIL = "GET_BILLER_HUSMO_FAIL";
export const GET_BILLER_DATASTATION = "GET_BILLER_DATASTATION";
export const GET_BILLER_KVDATA = "GET_BILLER_KVDATA";
export const GET_BILLER_ROSSYTECHS = "GET_BILLER_ROSSYTECHS";
export const GET_BILLER_DATASTATION_FAIL = "GET_BILLER_DATASTATION_FAIL";
export const GET_BILLER_DATAMESOLUTION = "GET_BILLER_DATAMESOLUTION";
export const GET_BILLER_MASKAWASUB = "GET_BILLER_MASKAWASUB";
export const GET_BILLER_VICKYBEST = "GET_BILLER_VICKYBEST";
export const GET_BILLER_DATAMESOLUTION_FAIL = "GET_BILLER_DATAMESOLUTION_FAIL";
export const GET_BILLER_TEETOP = "GET_BILLER_TEETOP";
export const GET_BILLER_KEMTECH = "GET_BILLER_KEMTECH";
export const GET_BILLER_MOAVOXPAY = "GET_BILLER_MOAVOXPAY";
export const GET_BILLER_TEETOP_FAIL = "GET_BILLER_TEETOP_FAIL";
export const GET_BILLER_SMEPLUG = "GET_BILLER_SMEPLUG";
export const GET_BILLER_SMEPLUG_FAIL = "GET_BILLER_SMEPLUG_FAIL";
export const GET_BILLER_OGDAMS = "GET_BILLER_OGDAMS";
export const GET_BILLER_OGDAMS_FAIL = "GET_BILLER_OGDAMS_FAIL";
export const GET_BILLER_HONOURWORLD = "GET_BILLER_HONOURWORLD";
export const GET_BILLER_HONOURWORLD_FAIL = "GET_BILLER_HONOURWORLD_FAIL";
export const GET_BILLER_VTPASS = "GET_BILLER_VTPASS";
export const GET_BILLER_VTPASS_FAIL = "GET_BILLER_VTPASS_FAIL";

// Data TO Buy
export const GET_DATA_TO_BUY = "GET_DATA_TO_BUY";
export const GET_DATA_TO_BUY_FAIL = "GET_DATA_TO_BUY_FAIL";
export const GET_DATA = "GET_DATA";
export const GET_DATA_FAIL = "GET_DATA_FAIL";
export const UPDATE_DATA = "UPDATE_DATA";
export const ADD_DATA = "ADD_DATA";
export const ADD_DATA_FAIL = "ADD_DATA_FAIL";
export const DELETE_DATA = "DELETE_DATA";
export const SEARCH_DATA = "SEARCH_DATA";
export const SEARCH_DATA_LOADING = "SEARCH_DATA_LOADING";
export const SEARCH_DATA_FAIL = "SEARCH_DATA_FAIL";
export const SEARCH_DATA_RELOAD = "SEARCH_DATA_RELOAD";

export const GET_USECASE = "GET_USECASE";
export const UPDATE_USECASE = "UPDATE_USECASE";
export const UPDATE_USECASE_FAIL = "UPDATE_USECASE_FAIL";

export const GET_UPGRADE = "GET_UPGRADE";
export const GET_UPGRADE_FAIL = "GET_UPGRADE_FAIL";
export const ADD_UPGRADE = "ADD_UPGRADE";
export const ADD_UPGRADE_FAIL = "ADD_UPGRADE_FAIL";
export const UPDATE_UPGRADE = "UPDATE_UPGRADE";

// Data
export const GET_DATA_DIRECT = "GET_DATA_DIRECT";
export const GET_DATA_DIRECT_FAIL = "GET_DATA_DIRECT_FAIL";
export const GET_DATA_LOADING = "GET_DATA_LOADING";
export const GET_CREATE_DATA = "GET_CREATE_DATA";
export const GET_CREATE_DATA_FAIL = "GET_CREATE_DATA_FAIL";
export const CREATE_DATA = "CREATE_DATA";
export const CREATE_DATA_FAIL = "CREATE_DATA_FAIL";

// Airtime
export const GET_AIRTIME = "GET_AIRTIME";
export const GET_AIRTIME_FAIL = "GET_AIRTIME_FAIL";
export const ADD_AIRTIME = "ADD_AIRTIME";
export const ADD_AIRTIME_FAIL = "ADD_AIRTIME_FAIL";
export const UPDATE_AIRTIME = "UPDATE_AIRTIME";
export const DELETE_AIRTIME = "DELETE_AIRTIME";
export const GET_AIRTIME_LOADING = "GET_AIRTIME_LOADING";
export const SEARCH_AIRTIME = "SEARCH_AIRTIME";
export const SEARCH_AIRTIME_LOADING = "SEARCH_AIRTIME_LOADING";
export const SEARCH_AIRTIME_FAIL = "SEARCH_AIRTIME_FAIL";
export const SEARCH_AIRTIME_RELOAD = "SEARCH_AIRTIME_RELOAD";

export const GET_AIRTIME_PIN = "GET_AIRTIME_PIN";
export const GET_AIRTIME_PIN_FAIL = "GET_AIRTIME_PIN_FAIL";
export const ADD_AIRTIME_PIN = "ADD_AIRTIME_PIN";
export const ADD_AIRTIME_PIN_FAIL = "ADD_AIRTIME_PIN_FAIL";
export const UPDATE_AIRTIME_PIN = "UPDATE_AIRTIME_PIN";
export const DELETE_AIRTIME_PIN = "DELETE_AIRTIME_PIN";
export const GET_AIRTIME_PIN_LOADING = "GET_AIRTIME_PIN_LOADING";
export const SEARCH_AIRTIME_PIN = "SEARCH_AIRTIME_PIN";
export const SEARCH_AIRTIME_PIN_LOADING = "SEARCH_AIRTIME_PIN_LOADING";
export const SEARCH_AIRTIME_PIN_FAIL = "SEARCH_AIRTIME_PIN_FAIL";
export const SEARCH_AIRTIME_PIN_RELOAD = "SEARCH_AIRTIME_PIN_RELOAD";

export const GET_BULK_SMS = "GET_BULK_SMS";
export const GET_BULK_SMS_FAIL = "GET_BULK_SMS_FAIL";
export const ADD_BULK_SMS = "ADD_BULK_SMS";
export const ADD_BULK_SMS_FAIL = "ADD_BULK_SMS_FAIL";
export const UPDATE_BULK_SMS = "UPDATE_BULK_SMS";
export const DELETE_BULK_SMS = "DELETE_BULK_SMS";
export const GET_BULK_SMS_LOADING = "GET_BULK_SMS_LOADING";
export const SEARCH_BULK_SMS = "SEARCH_BULK_SMS";
export const SEARCH_BULK_SMS_LOADING = "SEARCH_BULK_SMS_LOADING";
export const SEARCH_BULK_SMS_FAIL = "SEARCH_BULK_SMS_FAIL";
export const SEARCH_BULK_SMS_RELOAD = "SEARCH_BULK_SMS_RELOAD";

// Biz verification
export const GET_BIZ = "GET_BIZ";
export const GET_BIZ_FAIL = "GET_BIZ_FAIL";
export const ADD_BIZ = "ADD_BIZ";
export const ADD_BIZ_FAIL = "ADD_BIZ_FAIL";
export const UPDATE_BIZ = "UPDATE_BIZ";
export const DELETE_BIZ = "DELETE_BIZ";
export const GET_BIZ_LOADING = "GET_BIZ_LOADING";
export const SEARCH_BIZ = "SEARCH_BIZ";
export const SEARCH_BIZ_LOADING = "SEARCH_BIZ_LOADING";
export const SEARCH_BIZ_FAIL = "SEARCH_BIZ_FAIL";
export const SEARCH_BIZ_RELOAD = "SEARCH_BIZ_RELOAD";

// Education
export const GET_EDUCATION = "GET_EDUCATION";
export const GET_EDUCATION_FAIL = "GET_EDUCATION_FAIL";
export const GET_EDUCATION_DIRECT = "GET_EDUCATION_DIRECT";
export const GET_EDUCATION_DIRECT_FAIL = "GET_EDUCATION_DIRECT_FAIL";
export const UPDATE_EDUCATION = "UPDATE_EDUCATION";
export const ADD_EDUCATION = "ADD_EDUCATION";
export const DELETE_EDUCATION = "DELETE_EDUCATION";
export const GET_EDUCATION_LOADING = "GET_EDUCATION_LOADING";
export const ADD_EDUCATION_FAIL = "ADD_EDUCATION_FAIL";
export const SEARCH_EDUCATION = "SEARCH_EDUCATION";
export const SEARCH_EDUCATION_LOADING = "SEARCH_EDUCATION_LOADING";
export const SEARCH_EDUCATION_FAIL = "SEARCH_EDUCATION_FAIL";
export const SEARCH_EDUCATION_RELOAD = "SEARCH_EDUCATION_RELOAD";
export const CREATE_EDUCATION_PIN = "CREATE_EDUCATION_PIN";
export const DELETE_EDUCATION_PIN = "DELETE_EDUCATION_PIN";

// Electricity
export const GET_ELECTRICITY = "GET_ELECTRICITY";
export const GET_ELECTRICITY_FAIL = "GET_ELECTRICITY_FAIL";
export const GET_ELECTRICITY_DIRECT = "GET_ELECTRICITY_DIRECT";
export const GET_ELECTRICITY_DIRECT_FAIL = "GET_ELECTRICITY_DIRECT_FAIL";
export const UPDATE_ELECTRICITY = "UPDATE_ELECTRICITY";
export const ADD_ELECTRICITY = "ADD_ELECTRICITY";
export const DELETE_ELECTRICITY = "DELETE_ELECTRICITY";
export const GET_ELECTRICITY_LOADING = "GET_ELECTRICITY_LOADING";
export const ADD_ELECTRICITY_FAIL = "ADD_ELECTRICITY_FAIL";
export const SEARCH_ELECTRICITY = "SEARCH_ELECTRICITY";
export const SEARCH_ELECTRICITY_LOADING = "SEARCH_ELECTRICITY_LOADING";
export const SEARCH_ELECTRICITY_FAIL = "SEARCH_ELECTRICITY_FAIL";
export const SEARCH_ELECTRICITY_RELOAD = "SEARCH_ELECTRICITY_RELOAD";

export const GET_CABLE = "GET_CABLE";
export const GET_CABLE_FAIL = "GET_CABLE_FAIL";
export const GET_CABLE_DIRECT_PACKAGE = "GET_CABLE_DIRECT_PACKAGE";
export const GET_CABLE_DIRECT_PACKAGE_FAIL = "GET_CABLE_DIRECT_PACKAGE_FAIL";
export const GET_CABLE_DIRECT_TYPE = "GET_CABLE_DIRECT_TYPE";
export const GET_CABLE_DIRECT_TYPE_FAIL = "GET_CABLE_DIRECT_TYPE_FAIL";
export const UPDATE_CABLE = "UPDATE_CABLE";
export const ADD_CABLE = "ADD_CABLE";
export const DELETE_CABLE = "DELETE_CABLE";
export const GET_CABLE_LOADING = "GET_CABLE_LOADING";
export const ADD_CABLE_FAIL = "ADD_CABLE_FAIL";
export const SEARCH_CABLE = "SEARCH_CABLE";
export const SEARCH_CABLE_LOADING = "SEARCH_CABLE_LOADING";
export const SEARCH_CABLE_FAIL = "SEARCH_CABLE_FAIL";
export const SEARCH_CABLE_RELOAD = "SEARCH_CABLE_RELOAD";

export const GET_NETWORK = "GET_NETWORK";
export const GET_NETWORK_FAIL = "GET_NETWORK_FAIL";
export const ADD_NETWORK = "ADD_NETWORK";
export const UPDATE_NETWORK = "UPDATE_NETWORK";
export const DELETE_NETWORK = "DELETE_NETWORK";
export const ADD_NETWORK_FAIL = "ADD_NETWORK_FAIL";
export const GET_NETWORK_LOADING = "GET_NETWORK_LOADING";
export const GET_BANKS = "GET_BANKS";
export const GET_BANKS_FAIL = "GET_BANKS_FAIL";

export const GET_MANUAL_BANKS = "GET_MANUAL_BANKS";
export const ADD_MANUAL_BANKS = "ADD_MANUAL_BANKS";
export const ADD_MANUAL_BANKS_FAIL = "ADD_MANUAL_BANKS_FAIL";
export const DELETE_MANUAL_BANKS = "DELETE_MANUAL_BANKS";

export const GET_TERMS_CONDITIONS = "GET_TERMS_CONDITIONS";
export const ADD_TERMS_CONDITIONS = "ADD_TERMS_CONDITIONS";
export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY";
export const ADD_PRIVACY_POLICY = "ADD_PRIVACY_POLICY";
export const GET_FAQS = "GET_FAQS";
export const ADD_FAQS = "ADD_FAQS";
export const UPDATE_FAQS = "UPDATE_FAQS";
export const ADD_FAQS_FAIL = "ADD_FAQS_FAIL";
export const DELETE_FAQS = "DELETE_FAQS";

// WALLET
export const GET_WALLET = "GET_WALLET";
export const GET_WALLET_FAIL = "GET_WALLET_FAIL";
export const GET_WALLET_BALANCE = "GET_WALLET_BALANCE";
export const GET_WALLET_STAT = "GET_WALLET_STAT";
export const GET_WALLET_STAT_DAY = "GET_WALLET_STAT_DAY";
export const GET_WALLET_STAT_MONTH = "GET_WALLET_STAT_MONTH";
export const GET_WALLET_BALANCE_FAIL = "GET_WALLET_BALANCE_FAIL";
export const GET_WALLET_DETAILS = "GET_WALLET_DETAILS";
export const GET_WALLET_DETAILS_FAIL = "GET_WALLET_DETAILS_FAIL";
export const GET_COMMISSION = "GET_COMMISSION";
export const GET_GENERAL_COMMISSION = "GET_GENERAL_COMMISSION";
export const MOVE_COMMISSION = "MOVE_COMMISSION";
export const MOVE_COMMISSION_FAIL = "MOVE_COMMISSION_FAIL";
export const GET_REFERRAL = "GET_REFERRAL";
export const GET_ALL_REFERRAL = "GET_ALL_REFERRAL";
export const GET_GENERAL_REFERRAL = "GET_GENERAL_REFERRAL";
export const MOVE_REFERRAL = "MOVE_REFERRAL";
export const MOVE_REFERRAL_FAIL = "MOVE_REFERRAL_FAIL";
export const GET_BONUS = "GET_BONUS";
export const GET_ALL_BONUS = "GET_ALL_BONUS";
export const GET_ALL_MANUAL = "GET_ALL_MANUAL";
export const GIVE_BONUS = "GIVE_BONUS";
export const GIVE_BONUS_FAIL = "GIVE_BONUS_FAIL";
export const MOVE_BONUS = "MOVE_BONUS";
export const MOVE_BONUS_FAIL = "MOVE_BONUS_FAIL";
export const TRANSFER_FUND = "TRANSFER_FUND";
export const TRANSFER_FUND_FAIL = "TRANSFER_FUND_FAIL";
export const ADD_FUND = "ADD_FUND";
export const ADD_FUND_FAIL = "ADD_FUND_FAIL";
export const FUND_WALLET = "FUND_WALLET";
export const FUND_WALLET_FAIL = "FUND_WALLET_FAIL";
export const UPDATE_WALLET = "UPDATE_WALLET";
export const UPDATE_WALLET_FAIL = "UPDATE_WALLET_FAIL";
export const FUND_WALLET_FLUTTERWAVE = "FUND_WALLET_FLUTTERWAVE";
export const FUND_WALLET_FLUTTERWAVE_FAIL = "FUND_WALLET_FLUTTERWAVE_FAIL";
export const UPDATE_WALLET_FLUTTERWAVE = "UPDATE_WALLET_FLUTTERWAVE";
export const UPDATE_WALLET_FLUTTERWAVE_FAIL = "UPDATE_WALLET_FLUTTERWAVE_FAIL";
export const SEARCH_WALLET = "SEARCH_WALLET";
export const SEARCH_WALLET_LOADING = "SEARCH_WALLET_LOADING";
export const SEARCH_WALLET_FAIL = "SEARCH_WALLET_FAIL";
export const SEARCH_WALLET_RELOAD = "SEARCH_WALLET_RELOAD";

export const GET_FULL_USERS = "GET_FULL_USERS";
export const GET_FULL_USERS_FAIL = "GET_FULL_USERS_FAIL";

export const GET_CARDS = "GET_CARDS";
export const GET_CARDS_FAIL = "GET_CARDS_FAIL";

export const MANUAL_TRANSACTION = "MANUAL_TRANSACTION";
export const MANUAL_TRANSACTION_FAIL = "MANUAL_TRANSACTION_FAIL";
export const MANUAL_DEBIT = "MANUAL_DEBIT";
export const MANUAL_DEBIT_FAIL = "MANUAL_DEBIT_FAIL";

export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const GET_ALL_TRANSACTIONS_FAIL = "GET_ALL_TRANSACTIONS_FAIL";
export const GET_MY_TRANSACTIONS = "GET_MY_TRANSACTIONS";
export const GET_MY_TRANSACTIONS_FAIL = "GET_MY_TRANSACTIONS_FAIL";
export const GET_MONTH_TRANSACTIONS = "GET_MONTH_TRANSACTIONS";
export const GET_MONTH_TRANSACTIONS_FAIL = "GET_MONTH_TRANSACTIONS_FAIL";
export const GET_DAY_TRANSACTIONS = "GET_DAY_TRANSACTIONS";
export const GET_DAY_TRANSACTIONS_FAIL = "GET_DAY_TRANSACTIONS_FAIL";
export const GET_MY_MONTH_TRANSACTIONS = "GET_MY_MONTH_TRANSACTIONS";
export const GET_MY_MONTH_TRANSACTIONS_FAIL = "GET_MY_MONTH_TRANSACTIONS_FAIL";
export const GET_MY_DAY_TRANSACTIONS = "GET_MY_DAY_TRANSACTIONS";
export const GET_MY_DAY_TRANSACTIONS_FAIL = "GET_MY_DAY_TRANSACTIONS_FAIL";
export const SEARCH_TRANSACTION = "SEARCH_TRANSACTION";
export const SEARCH_TRANSACTION_LOADING = "SEARCH_TRANSACTION_LOADING";
export const SEARCH_TRANSACTION_FAIL = "SEARCH_TRANSACTION_FAIL";
export const SEARCH_TRANSACTION_RELOAD = "SEARCH_TRANSACTION_RELOAD";
export const SEARCH_MY_TRANSACTION = "SEARCH_MY_TRANSACTION";
export const SEARCH_MY_TRANSACTION_LOADING = "SEARCH_MY_TRANSACTION_LOADING";
export const SEARCH_MY_TRANSACTION_FAIL = "SEARCH_MY_TRANSACTION_FAIL";
export const SEARCH_MY_TRANSACTION_RELOAD = "SEARCH_MY_TRANSACTION_RELOAD";

export const GET_DATA_TRANSACTIONS = "GET_DATA_TRANSACTIONS";
export const GET_DATA_TRANSACTIONS_FAIL = "GET_DATA_TRANSACTIONS_FAIL";

export const DELETE_NOTIFICATONS = "DELETE_NOTIFICATONS";
export const GET_NOTIFICATONS = "GET_NOTIFICATONS";
export const GET_NOTIFICATONS_FAIL = "GET_NOTIFICATONS_FAIL";
export const GET_MY_NOTIFICATONS = "GET_MY_NOTIFICATONS";
export const GET_MY_NOTIFICATONS_FAIL = "GET_MY_NOTIFICATONS_FAIL";

export const GENERATE_VIRTUAL = "GENERATE_VIRTUAL";
export const GENERATE_VIRTUAL_FAIL = "GENERATE_VIRTUAL_FAIL";

export const GET_AIRTIME_CONVERTER = "GET_AIRTIME_CONVERTER";
export const GET_AIRTIME_CONVERTER_FAIL = "GET_AIRTIME_CONVERTER_FAIL";
export const ADD_AIRTIME_CONVERTER = "ADD_AIRTIME_CONVERTER";
export const ADD_AIRTIME_CONVERTER_FAIL = "ADD_AIRTIME_CONVERTER_FAIL";
export const GET_CONVERTER_NUMBER = "GET_CONVERTER_NUMBER";
export const GET_CONVERTER_NUMBER_FAIL = "GET_CONVERTER_NUMBER_FAIL";
export const ADD_CONVERTER_NUMBER = "ADD_CONVERTER_NUMBER";
export const ADD_CONVERTER_NUMBER_FAIL = "ADD_CONVERTER_NUMBER_FAIL";
export const UPDATE_CONVERTER_NUMBER = "UPDATE_CONVERTER_NUMBER";
export const UPDATE_CONVERTER_NUMBER_FAIL = "UPDATE_CONVERTER_NUMBER_FAIL";
export const UPDATE_CONVERTER_DETAIL = "UPDATE_CONVERTER__DETAIL";
export const UPDATE_CONVERTER_DETAIL_FAIL = "UPDATE_CONVERTER_DETAIL_FAIL";
export const SEARCH_CONVERTER = "SEARCH_CONVERTER";
export const SEARCH_CONVERTER_LOADING = "SEARCH_CONVERTER_LOADING";
export const SEARCH_CONVERTER_FAIL = "SEARCH_CONVERTER_FAIL";
export const SEARCH_CONVERTER_RELOAD = "SEARCH_CONVERTER_RELOAD";

export const WALLET_HISTORY_USER = "WALLET_HISTORY_USER";
export const WALLET_HISTORY_USER_LOADING = "WALLET_HISTORY_USER_LOADING";
export const WALLET_HISTORY_USER_FAIL = "WALLET_HISTORY_USER_FAIL";

export const PURCHASE_HISTORY_USER = "PURCHASE_HISTORY_USER";
export const PURCHASE_HISTORY_USER_LOADING = "PURCHASE_HISTORY_USER_LOADING";
export const PURCHASE_HISTORY_USER_FAIL = "PURCHASE_HISTORY_USER_FAIL";

export const SEARCH_PENDING_HISTORY = "SEARCH_PENDING_HISTORY";
export const SEARCH_PENDING_HISTORY_LOADING = "SEARCH_PENDING_HISTORY_LOADING";
export const SEARCH_PENDING_HISTORY_FAIL = "SEARCH_PENDING_HISTORY_FAIL";
export const SEARCH_PENDING_HISTORY_RELOAD = "SEARCH_PENDING_HISTORY_RELOAD";
export const GET_PENDING_HISTORY = "GET_PENDING_HISTORY";
export const GET_PENDING_HISTORY_FAIL = "GET_PENDING_HISTORY_FAIL";
export const UPDATE_PENDING_HISTORY = "UPDATE_PENDING_HISTORY";
export const UPDATE_PENDING_HISTORY_FAIL = "UPDATE_PENDING_HISTORY_FAIL";

export const SEARCH_PENDING_HISTORY_VIRTUAL = "SEARCH_PENDING_HISTORY_VIRTUAL";
export const SEARCH_PENDING_HISTORY_VIRTUAL_LOADING =
	"SEARCH_PENDING_HISTORY_LOADING";
export const SEARCH_PENDING_HISTORY_VIRTUAL_FAIL =
	"SEARCH_PENDING_HISTORY_VIRTUAL_FAIL";
export const SEARCH_PENDING_HISTORY_VIRTUAL_RELOAD =
	"SEARCH_PENDING_HISTORY_VIRTUAL_RELOAD";
export const GET_PENDING_HISTORY_VIRTUAL = "GET_PENDING_HISTORY_VIRTUAL";
export const GET_PENDING_HISTORY_VIRTUAL_FAIL =
	"GET_PENDING_HISTORY_VIRTUAL_FAIL";
export const UPDATE_PENDING_HISTORY_VIRTUAL = "UPDATE_PENDING_HISTORY_VIRTUAL";
export const UPDATE_PENDING_HISTORY_VIRTUAL_FAIL =
	"UPDATE_PENDING_HISTORY_VIRTUAL_FAIL";

export const SEARCH_PENDING_HISTORY_CARD = "SEARCH_PENDING_HISTORY_CARD";
export const SEARCH_PENDING_HISTORY_CARD_LOADING =
	"SEARCH_PENDING_HISTORY_LOADING";
export const SEARCH_PENDING_HISTORY_CARD_FAIL =
	"SEARCH_PENDING_HISTORY_CARD_FAIL";
export const SEARCH_PENDING_HISTORY_CARD_RELOAD =
	"SEARCH_PENDING_HISTORY_CARD_RELOAD";
export const GET_PENDING_HISTORY_CARD = "GET_PENDING_HISTORY_CARD";
export const GET_PENDING_HISTORY_CARD_FAIL = "GET_PENDING_HISTORY_CARD_FAIL";
export const UPDATE_PENDING_HISTORY_CARD = "UPDATE_PENDING_HISTORY_CARD";
export const UPDATE_PENDING_HISTORY_CARD_FAIL =
	"UPDATE_PENDING_HISTORY_CARD_FAIL";

export const DELETE_TRANSACTION = "DELETE_TRANSACTION";
export const DELETE_MULTIPLE_TRANSACTION = "DELETE_MULTIPLE_TRANSACTION";
export const DELETE_TRANSACTION_FAIL = "DELETE_TRANSACTION_FAIL";

export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const UPDATE_MULTIPLE_TRANSACTION = "UPDATE_MULTIPLE_TRANSACTION";
export const UPDATE_TRANSACTION_FAIL = "UPDATE_TRANSACTION_FAIL";

export const SOCKET = "SOCKET";
export const SOCKET_ONLINE = "SOCKET_ONLINE";

export const GET_ELECTRICITY_TO_BUY = "GET_ELECTRICITY_TO_BUY";
export const GET_ELECTRICITY_TO_BUY_FAIL = "GET_ELECTRICITY_TO_BUY_FAIL";
export const GET_ELECTRICITY_BUNDLE = "GET_ELECTRICITY_BUNDLE";
export const GET_ELECTRICITY_BUNDLE_FAIL = "GET_ELECTRICITY_BUNDLE_FAIL";
export const UPDATE_ELECTRICITY_BUNDLE = "UPDATE_ELECTRICITY_BUNDLE";
export const ADD_ELECTRICITY_BUNDLE = "ADD_ELECTRICITY_BUNDLE";
export const ADD_ELECTRICITY_BUNDLE_FAIL = "ADD_ELECTRICITY_BUNDLE_FAIL";
export const DELETE_ELECTRICITY_BUNDLE = "DELETE_ELECTRICITY_BUNDLE";
export const SEARCH_ELECTRICITY_BUNDLE = "SEARCH_ELECTRICITY_BUNDLE";
export const SEARCH_ELECTRICITY_BUNDLE_LOADING =
	"SEARCH_ELECTRICITY_BUNDLE_LOADING";
export const SEARCH_ELECTRICITY_BUNDLE_FAIL = "SEARCH_ELECTRICITY_BUNDLE_FAIL";
export const SEARCH_ELECTRICITY_BUNDLE_RELOAD =
	"SEARCH_ELECTRICITY_BUNDLE_RELOAD";

export const GET_EDUCATION_TO_BUY = "GET_EDUCATION_TO_BUY";
export const GET_EDUCATION_TO_BUY_FAIL = "GET_EDUCATION_TO_BUY_FAIL";
export const GET_EDUCATION_BUNDLE = "GET_EDUCATION_BUNDLE";
export const GET_EDUCATION_BUNDLE_FAIL = "GET_EDUCATION_BUNDLE_FAIL";
export const UPDATE_EDUCATION_BUNDLE = "UPDATE_EDUCATION_BUNDLE";
export const ADD_EDUCATION_BUNDLE = "ADD_EDUCATION_BUNDLE";
export const ADD_EDUCATION_BUNDLE_FAIL = "ADD_EDUCATION_BUNDLE_FAIL";
export const DELETE_EDUCATION_BUNDLE = "DELETE_EDUCATION_BUNDLE";
export const SEARCH_EDUCATION_BUNDLE = "SEARCH_EDUCATION_BUNDLE";
export const SEARCH_EDUCATION_BUNDLE_LOADING =
	"SEARCH_EDUCATION_BUNDLE_LOADING";
export const SEARCH_EDUCATION_BUNDLE_FAIL = "SEARCH_EDUCATION_BUNDLE_FAIL";
export const SEARCH_EDUCATION_BUNDLE_RELOAD = "SEARCH_EDUCATION_BUNDLE_RELOAD";

export const GET_CABLES_TYPES = "GET_CABLES_TYPES";
export const GET_CABLES_TYPES_FAIL = "GET_CABLES_TYPES_FAIL";
export const ADD_CABLES_TYPES = "ADD_CABLES_TYPES";
export const UPDATE_CABLES_TYPES = "UPDATE_CABLES_TYPES";
export const DELETE_CABLES_TYPES = "DELETE_CABLES_TYPES";
export const ADD_CABLES_TYPES_FAIL = "ADD_CABLES_TYPES_FAIL";

export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_ACTIVITY_FAIL = "GET_ACTIVITY_FAIL";
export const SEARCH_ACTIVITY = "SEARCH_ACTIVITY";
export const SEARCH_ACTIVITY_LOADING = "SEARCH_ACTIVITY_LOADING";
export const SEARCH_ACTIVITY_FAIL = "SEARCH_ACTIVITY_FAIL";
export const SEARCH_ACTIVITY_RELOAD = "SEARCH_ACTIVITY_RELOAD";

export const WALLET_PROVIDER_STAT = "WALLET_PROVIDER_STAT";
export const TRANSACTIONS_STAT = "TRANSACTIONS_STAT";
export const COMMISSION_TRANSACTIONS_STAT = "COMMISSION_TRANSACTIONS_STAT";
export const DATA_TRANSACTIONS_STAT = "DATA_TRANSACTIONS_STAT";
export const FIND_TRANSACTIONS_STAT = "FIND_TRANSACTIONS_STAT";

export const GET_BILLER_FUNDING = "GET_BILLER_FUNDING";
export const ADD_BILLER_FUNDING = "ADD_BILLER_FUNDING";
export const ADD_BILLER_FUNDING_FAIL = "ADD_BILLER_FUNDING_FAIL";

export const GET_MONNIFY = "GET_MONNIFY";
export const GET_MANUAL = "GET_MANUAL";
export const GET_PAYSTACK = "GET_PAYSTACK";
export const GET_BUDPAY = "GET_BUDPAY";
export const GET_PAYVESSEL = "GET_PAYVESSEL";
export const GET_FLUTTERWAVE = "GET_FLUTTERWAVE";
export const GET_PROVIDER_FAIL = "GET_PROVIDER_FAIL";
export const SEARCH_PROVIDER = "SEARCH_PROVIDER";
export const SEARCH_PROVIDER_LOADING = "SEARCH_PROVIDER_LOADING";
export const SEARCH_PROVIDER_FAIL = "SEARCH_PROVIDER_FAIL";
export const SEARCH_PROVIDER_RELOAD = "SEARCH_PROVIDER_RELOAD";

export const SEARCH_RELOAD = "SEARCH_RELOAD";
export const MANAGE_WALLET_PIN = "MANAGE_WALLET_PIN";
export const TRANSACTION_DETAILS = "TRANSACTION_DETAILS";
export const TRANSACTION_DETAILS_USER = "TRANSACTION_DETAILS_USER";
export const TRANSACTION_DETAILS_RESELLER = "TRANSACTION_DETAILS_RESELLER";
export const TRANSACTION_DETAILS_STANDALONE = "TRANSACTION_DETAILS_STANDALONE";
export const TRANSACTION_DETAILS_TOPUSER = "TRANSACTION_DETAILS_TOPUSER";
export const TRANSACTION_DETAILS_CUSTOMERCARE =
	"TRANSACTION_DETAILS_CUSTOMERCARE";
export const TRANSACTION_DETAILS_MANAGERS = "TRANSACTION_DETAILS_MANAGERS";
export const TRANSACTION_DETAILS_MARKETERS = "TRANSACTION_DETAILS_MARKETERS";
export const TRANSACTION_DETAILS_DEVELOPERS = "TRANSACTION_DETAILS_DEVELOPERS";
export const TRANSACTION_DETAILS_ACCOUNTANT = "TRANSACTION_DETAILS_ACCOUNTANT";
export const TRANSACTION_DETAILS_SUPERADMIN = "TRANSACTION_DETAILS_SUPERADMIN";

export const GET_PERMISSION = "GET_PERMISSION";
export const UDPATE_PERMISSION = "UDPATE_PERMISSION";
export const GET_PERMISSION_FAIL = "GET_PERMISSION_FAIL";

export const GET_CGWALLET = "GET_CGWALLET";
export const GET_CGWALLET_FAIL = "GET_CGWALLET_FAIL";
export const ADD_CGWALLET = "ADD_CGWALLET";
export const UPDATE_CGWALLET = "UPDATE_CGWALLET";
export const DELETE_CGWALLET = "DELETE_CGWALLET";
export const GET_CGWALLET_LOADING = "GET_CGWALLET_LOADING";

export const GET_CGWALLET_HISTORY_PURCHASE = "GET_CGWALLET_HISTORY_PURCHASE";
export const GET_CGWALLET_HISTORY_USAGE = "GET_CGWALLET_HISTORY_USAGE";
export const GET_CGWALLET_HISTORY_FAIL = "GET_CGWALLET_HISTORY_FAIL";
export const ADD_CGWALLET_HISTORY = "ADD_CGWALLET_HISTORY";
export const GET_CGWALLET_HISTORY_LOADING = "GET_CGWALLET_HISTORY_LOADING";
export const SEARCH_CGWALLET_HISTORY = "SEARCH_CGWALLET_HISTORY";
export const SEARCH_CGWALLET_HISTORY_LOADING =
	"SEARCH_CGWALLET_HISTORY_LOADING";
export const SEARCH_CGWALLET_HISTORY_FAIL = "SEARCH_CGWALLET_HISTORY_FAIL";

export const GET_CGWALLET_HISTORY_PURCHASE_USER =
	"GET_CGWALLET_HISTORY_PURCHASE_USER";
export const GET_CGWALLET_HISTORY_USAGE_USER =
	"GET_CGWALLET_HISTORY_USAGE_USER";
export const GET_CGWALLET_HISTORY_FAIL_USER = "GET_CGWALLET_HISTORY_FAIL_USER";
export const ADD_CGWALLET_HISTORY_USER = "ADD_CGWALLET_HISTORY_USER";
export const GET_CGWALLET_HISTORY_LOADING_USER =
	"GET_CGWALLET_HISTORY_LOADING_USER";
export const SEARCH_CGWALLET_HISTORY_USER = "SEARCH_CGWALLET_HISTORY_USER";
export const SEARCH_CGWALLET_HISTORY_LOADING_USER =
	"SEARCH_CGWALLET_HISTORY_LOADING_USER";
export const SEARCH_CGWALLET_HISTORY_FAIL_USER =
	"SEARCH_CGWALLET_HISTORY_FAIL_USER";
export const SEARCH_ALL_USERS_HISTORY = "SEARCH_ALL_USERS_HISTORY";
export const SEARCH_ALL_USERS_HISTORY_LOADING =
	"SEARCH_ALL_USERS_HISTORY_LOADING";
export const SEARCH_ALL_USERS_HISTORY_FAIL = "SEARCH_ALL_USERS_HISTORY_FAIL";


export const GET_BLACKLIST_TELEPHONE = "GET_BLACKLIST_TELEPHONE";
export const GET_BLACKLIST_METERNO = "GET_BLACKLIST_METERNO";
export const GET_BLACKLIST_SMARTCARDNO = "GET_BLACKLIST_SMARTCARDNO";
export const GET_BLACKLIST_FAIL = "GET_BLACKLIST_FAIL";
export const ADD_BLACKLIST = "ADD_BLACKLIST";
export const UPDATE_BLACKLIST = "UPDATE_BLACKLIST";
export const DELETE_BLACKLIST = "DELETE_BLACKLIST";
export const GET_BLACKLIST_LOADING = "GET_BLACKLIST_LOADING";

export const SEARCH_BLACKLIST = "SEARCH_BLACKLIST";
export const SEARCH_BLACKLIST_LOADING = "SEARCH_BLACKLIST_LOADING";
export const SEARCH_BLACKLIST_FAIL = "SEARCH_BLACKLIST_FAIL";