import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container } from "reactstrap";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { GlobalState } from "../Data/Context";
import { Buttons } from "../Utils";
import bg1 from "../Assets/Group3610.png";
import image from "../Assets/Group 42903.png";

const Login = () => {
	const {
		loginUser,
		// getSetTempUser,
		auth,
	} = useContext(GlobalState);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	let [typePass, setTypePass] = useState(false),
		init = {
			email: "",
			password: "",
		},
		[stateData, setStateData] = useState(init),
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		navigate = useNavigate(),
		textChange =
			name =>
			({ target: { value } }) => {
				setStateData({ ...stateData, [name]: value });
			};

	let handleSubmit = async e => {
		e.preventDefault();
		if (!stateData?.password || !stateData?.email) return;
		setLoading(true);
		await loginUser(stateData);
		// getSetTempUser("auth");
		setLoading(false);
		setSubmit(true);
	};

	useEffect(() => {
		if (submit && auth?.isLoggedIn) {
			setSubmit(false);
			navigate("/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, auth?.isLoggedIn]);

	return (
		<>
			<DefaultAuthComponent>
				<h3 className="text-capitalize Lexend">Welcome back</h3>
				<small className="mb-4 d-block">
					Welcome back! Please enter your details.
				</small>
				<form>
					<label htmlFor="email">Email</label>
					<div className="mb-3">
						<input
							type="email"
							required
							name="email"
							className="form-control py-3"
							value={stateData.email}
							onChange={textChange("email")}
							// onPaste={e => e?.preventDefault()}
						/>
					</div>
					<label htmlFor="Password">Password</label>
					<div className="mb-5 show-hide position-relative">
						<input
							type={typePass ? "text" : "password"}
							required
							name="password"
							className="form-control py-3"
							value={stateData.password}
							onChange={textChange("password")}
						/>
						<span className="" onClick={() => setTypePass(!typePass)}>
							{!typePass ? <BsEye /> : <BsEyeSlash />}
						</span>
					</div>
					<p className="my-4 Lexend">
						Forgot my login details{" "}
						<Link
							to={`/forget-password`}
							className="text-decoration-none textColor2 fw-600">
							Get help signing in
						</Link>{" "}
					</p>
					<Buttons
						onClick={handleSubmit}
						loading={loading}
						title={"sign in"}
						css="btn btn-primary1 text-capitalize py-3 w-100 my-4"
					/>
				</form>
				<div className="my-3 d-flex align-items-center justify-content-between">
					<Link to={`/activate`} className="btn text-capitalize Lexend fw-600">
						verify mail
					</Link>{" "}
				</div>
			</DefaultAuthComponent>
		</>
	);
};

export default Login;

export const DefaultAuthComponent = ({ children }) => {
	return (
		<>
			<div className="bg-white">
				<div className="row mx-0">
					<Container
						className="heroBg col-md-7"
						style={{
							background: `url(${bg1})`,
						}}>
						<section className="d-flex justify-content-center align-items-center fullscreen2 py-3 py-md-5">
							<div
								className="m-auto px-3 py-5 rounded w-100"
								style={{
									maxWidth: "550px",
								}}>
								<Container className="px-lg-5 px-3">{children}</Container>
							</div>
						</section>
					</Container>
					<div className="col-lg-5 d-none d-lg-flex fullscreen2 bg-select-blue">
						<img
							src={image}
							alt="Banner"
							className="img-fluid m-auto h-100 imgFluid"
						/>
					</div>
				</div>
			</div>
		</>
	);
};
