import React, { useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { GlobalState } from "../../Data/Context";

const Controls = () => {
	let {
		setStateName,
		//  getBillerData
	} = useContext(GlobalState);
	useEffect(() => {
		setStateName("Controls");
		// getBillerData("vtpass");
		// if (
		// 	![
		// 		"Moztelecom",
		// 		"Moavox Pay",
		// 		"Vickybest Telecom",
		// 		"V12 Pay",
		// 		"Fantastic Fisayo",
		// 		"Mel Data Services",
		// 		"Yema Telecommunications",
		// 		"SPRINT DATA",
		// 		"Vee's Telecom",
		// 		"Bliz",
		// 		"Allbrite Digitals",
		// 		"Noney Data Plug",
		// 		"Rosezee Telecom",
		// 		"Durable Telecommunications",
		// 		"MySub",
		// 		"M.O Data Plug",
		// 		"ALLAHU LATEEF INTEGRATED SERVICES LIMITED",
		// 	]?.includes(process.env.REACT_APP_NAME)
		// ) {
		// 	getBillerData("honourworld");
		// }
		// if (
		// 	![
		// 		"Vickybest Telecom",
		// 		"Fantastic Fisayo",
		// 		"Mel Data Services",
		// 		"Yema Telecommunications",
		// 		"SPRINT DATA",
		// 		"Vee's Telecom",
		// 		"Bliz",
		// 		"Allbrite Digitals",
		// 		"Noney Data Plug",
		// 		"Rosezee Telecom",
		// 		"Durable Telecommunications",
		// 		"MySub",
		// 		"M.O Data Plug",
		// 		"ALLAHU LATEEF INTEGRATED SERVICES LIMITED",
		// 	]?.includes(process.env.REACT_APP_NAME)
		// ) {
		// 	getBillerData("datastation");
		// }
		// if (
		// 	["Fantastic Fisayo", "Teetop Digital"]?.includes(
		// 		process.env.REACT_APP_NAME
		// 	)
		// ) {
		// 	getBillerData("clubkonnect");
		// }
		// if (
		// 	["Teetop Digital", "Kemtech Enterprises"]?.includes(
		// 		process.env.REACT_APP_NAME
		// 	)
		// ) {
		// 	getBillerData("husmo");
		// 	if (["Teetop Digital"]?.includes(process.env.REACT_APP_NAME))
		// 		getBillerData("rossytechs");
		// }
		// if (
		// 	[
		// 		"Vickybest Telecom",
		// 		// "V12 Pay",
		// 		"Fantastic Fisayo",
		// 		"Moztelecom",
		// 		"Moavox Pay",
		// 		"Mel Data Services",
		// 		"Yema Telecommunications",
		// 		"SPRINT DATA",
		// 		"Bliz",
		// 		"Vee's Telecom",
		// 		"Allbrite Digitals",
		// 		"Noney Data Plug",
		// 		"Rosezee Telecom",
		// 		"Durable Telecommunications",
		// 		"MySub",
		// 		"M.O Data Plug",
		// 		"ALLAHU LATEEF INTEGRATED SERVICES LIMITED",
		// 	]?.includes(process.env.REACT_APP_NAME)
		// ) {
		// 	getBillerData("teetop");
		// }
		// if (["Vickybest Telecom"]?.includes(process.env.REACT_APP_NAME)) {
		// 	getBillerData("datamesolution");
		// }
		// if (["SPRINT DATA"]?.includes(process.env.REACT_APP_NAME)) {
		// 	getBillerData("maskawasub");
		// }
		// if (["Vee's Telecom"]?.includes(process.env.REACT_APP_NAME)) {
		// 	getBillerData("vickybest");
		// }
		// if (
		// 	["Kemtech Enterprises", "V12 Pay"]?.includes(process.env.REACT_APP_NAME)
		// ) {
		// 	getBillerData("moavoxpay");
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let navigate = useNavigate(),
		params = useParams(),
		controlsTab = [
			{
				name: "manage biller",
				type: "link",
				link: `/${params?.page}/biller`,
				color: "linear-gradient(90deg, #DE0DE2 16.14%, #0E102D 101.45%)",
				textColor: "white",
				show: true,
			},
			{
				name: "manage network",
				type: "link",
				link: `/${params?.page}/network`,
				color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
				show: true,
			},
			{
				textColor: "white",
				name: "manage products",
				type: "link",
				link: `/${params?.page}/manage-products`,
				color: "linear-gradient(90deg, #F45F83 16.14%, #9E1A2A 101.45%)",
				show: true,
			},
			{
				name: "manage category",
				type: "link",
				link: `/${params?.page}/category`,
				color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
				textColor: "white",
				show: true,
			},
			{
				name: "manage data plans",
				type: "link",
				link: `/${params?.page}/data`,
				color:
					"linear-gradient(96.86deg, rgba(83, 242, 147, 0.8) 18.88%, rgba(158, 255, 0, 0.8) 125.77%)",
				show: true,
			},
			{
				name: "manage cables subscription",
				type: "link",
				link: `/${params?.page}/cables`,
				color: "linear-gradient(90deg, #DE0DE2 16.14%, #0E102D 101.45%)",
				textColor: "white",
				show: true,
			},
			{
				name: "manage bills plans",
				type: "link",
				link: `/${params?.page}/electricity`,
				color: "linear-gradient(96.86deg, #53F293 18.88%, #9EFF00 125.77%)",
				show: true,
			},
			{
				name: "manage education",
				type: "link",
				link: `/${params?.page}/education`,
				color:
					"linear-gradient(90deg, rgba(228, 51, 105, 0.7) 16.14%, rgba(194, 14, 25, 0.7) 101.45%)",
				show: true,
			},
			{
				name: "manage CG Wallet",
				type: "link",
				link: `/${params?.page}/manage-cgwallet`,
				color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
				show: ["Teetop Digital", "Kemtech Enterprises"]?.includes(
					process.env.REACT_APP_NAME
				),
			},
			{
				name: "manage CG Wallet History",
				type: "link",
				link: `/${params?.page}/manage-cgwallet-history`,
				color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
				textColor: "white",
				show: ["Teetop Digital", "Kemtech Enterprises"]?.includes(
					process.env.REACT_APP_NAME
				),
			},
			{
				name: "manage blacklist",
				type: "link",
				link: `/${params?.page}/blacklist`,
				color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
				textColor: "white",
				show: true,
			},
		];

	return (
		<div className="bg-white aboutScreen">
			<Container>
				<div className="row mx-0 g-2 g-md-4 py-4 py-md-5">
					{controlsTab
						?.filter(it => it?.show)
						?.map((item, i) => (
							<div className="col-6 col-md-4 productCard" key={i}>
								<span
									onClick={() => {
										if (item?.type === "link") {
											navigate(item?.link);
										} else if (item?.type === "button") {
										}
									}}
									className="d-flex align-items-center justify-content-center text2 myCursor text-decoration-none h-100 eachProduct fontReduceBig textTrunc p-2 p-md-0 py-3 py-md-0 h-100"
									style={{
										background: item?.color,
										borderRadius: "30px",
										color: item?.textColor ? item?.textColor : "#000",
									}}>
									<span className="textTrunc fontInherit">{item?.name}</span>
								</span>
							</div>
						))}
				</div>
			</Container>
		</div>
	);
};

export default Controls;
