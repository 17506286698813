import React, { useContext, useEffect } from "react";
import { Container } from "reactstrap";
import { ThreeBoxBar, UserListOne } from "../../Components/Users";
import icon1 from "../../Assets/5.png";
import icon2 from "../../Assets/x36.png";
import icon3 from "../../Assets/Allura Online Searching.png";
import { Link } from "react-router-dom";
import { GlobalState } from "../../Data/Context";

const AgentsMain = () => {
	let { setStateName } = useContext(GlobalState);
	useEffect(() => {
		setStateName("agents");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let usersArr = [
		{
			icon: icon1,
			name: "total users",
			number: 6500,
			color: "linear-gradient(90.18deg, #84C7DB -52.19%, #377FB6 81.92%)",
			link: "/users",
		},
		{
			icon: icon2,
			name: "total referrals",
			number: 5000,
			color: "linear-gradient(90deg, #D88ADA 16.14%, #CA64FB 101.45%)",
		},
		{
			icon: icon3,
			name: "total agents",
			number: 4000,
			color: "linear-gradient(96.86deg, #F4EA75 18.88%, #F7BA5E 125.77%)",
		},
	];
	return (
		<Container>
			<ThreeBoxBar list={usersArr} />
			<Link
				to={`/users`}
				style={{ borderRadius: "30px" }}
				className="btn-primary1 text-capitalize py-3 px-4 px-lg-5 my-4 btn">
				Users
			</Link>
			<UserListOne />
		</Container>
	);
};

export default AgentsMain;
