import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { useParams } from "react-router-dom";
import { GlobalState } from "../../Data/Context";

const Administration = () => {
	let {
		setStateName,
		user,
		reseller,
		topuser,
		numberWithCommas,
		getUserPrivileges,
		manageUpgrade,
		getAllUserTransactionAmount,
		upgrade,
		standalone,
	} = useContext(GlobalState);
	useEffect(() => {
		manageUpgrade();
		getUserPrivileges("user");
		getUserPrivileges("reseller");
		getUserPrivileges("topuser");
		getUserPrivileges("superadmin");
		getAllUserTransactionAmount("user");
		getAllUserTransactionAmount("reseller");
		getAllUserTransactionAmount("topuser");
		getAllUserTransactionAmount("superadmin");
		if (
			["Teetop Digital", "TEETOP DIGITAL"]?.includes(process.env.REACT_APP_NAME)
		) {
			getUserPrivileges("standalone");
			getAllUserTransactionAmount("standalone");
		}
		setStateName("manage general users");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let productArr = [
		{
			name: "Manage User",
			link: "/users",
			color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
			textColor: "white",
			number: user?.paginate?.total,
			show: true,
		},
		{
			name: "Manage Reseller",
			link: "/reseller",
			color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
			number: reseller?.paginate?.total,
			show: true,
		},
		{
			name: "Manage Topuser",
			link: "/topuser",
			color: "linear-gradient(90deg, #E43369 16.14%, #C20E19 101.45%)",
			textColor: "white",
			number: topuser?.paginate?.total,
			show: true,
		},
		{
			textColor: "black",
			name: "Manage Standalone",
			link: "/standalone",
			color: "linear-gradient(96.86deg, #53F293 18.88%, #9EFF00 125.77%)",
			number: standalone?.paginate?.total,
			show: ["Teetop Digital", "TEETOP DIGITAL"]?.includes(
				process.env.REACT_APP_NAME
			),
		},
		{
			name: "Manage Upgrade request",
			link: "/upgrade",
			color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
			textColor: "white",
			number: upgrade?.paginate?.total,
			show: true,
		},
	];

	let params = useParams();

	return (
		<Container>
			<div className="row mx-0 g-2 g-md-4 py-4 py-md-5">
				{productArr
					?.filter(ic => ic?.show)
					?.map((item, i) => (
						<div className="col-6 col-md-4 productCard" key={i}>
							<Link
								to={
									item?.type === "button"
										? "#"
										: `/${params?.page}${item?.link}`
								}
								className="d-flex align-items-center justify-content-center text2 myCursor text-decoration-none h-100 eachProduct fontReduceBig textTrunc p-2 p-md-0 py-3 py-md-0 h-100 flex-column"
								style={{
									background: item?.color,
									borderRadius: "30px",
									color: item?.textColor ? item?.textColor : "#000",
								}}>
								<span className="textTrunc fontInherit">{item?.name}</span>
								<span className="textTrunc fontInherit textBig Lexend text5">
									{item?.number ? numberWithCommas(item?.number) : ""}
								</span>
							</Link>
						</div>
					))}
			</div>
		</Container>
	);
};

export default Administration;
